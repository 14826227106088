import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/bed.png';

const HospitalizationStay = ({ scores }) => {
  return (
    <div className="hospitalization-stay metrics-group">
      <Metric
        title="Permanência"
        image={Icon}
        scores={[
          {
            title: 'Dias',
            value: scores.RM16
          },
          {
            title: 'Especialidade',
            value: scores.RM17,
            className: 'circle'
          },
          {
            title: 'US+30%',
            value: scores.RM18,
            className: 'circle',
            tooltip: 'Benchmarking americano (em dias) acrescido em 30%'
          }
        ]}
      />

      <div className="clearfix"></div>
    </div>
  );
};

HospitalizationStay.propTypes = {
  scores: PropTypes.shape({
    RM16: PropTypes.string.isRequired,
    RM17: PropTypes.string.isRequired,
    RM18: PropTypes.string.isRequired
  })
};

export default HospitalizationStay;
