import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';

import routes from 'routes';
import Layout from 'components/Layout';

const App = () => (
    <BrowserRouter>
      <React.Fragment>
        {routes.map((item) => {
            return (
              <item.route
                key={item.path}
                path={item.path}
                exact={true}
                roles={item.roles}
                component={props => {
                  return (
                    <Layout {...props}>
                      <item.component {...props} />
                    </Layout>
                  );
                }}
              />
            );
        })}
      </React.Fragment>
      <Route component={() => <Redirect to="/" />} />
    </BrowserRouter>
);

export default App;
