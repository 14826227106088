import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/event.png';

const Event = ({ scores }) => {
  return (
    <div className="event metrics-group">
      <Metric 
        title="Coordenação de Eventos Científicos no IEP"
        image={Icon}
        scores={[
          {
            value: scores.RM37
          }
        ]}
      />
    </div>
  );
};

Event.propTypes = {
  scores: PropTypes.shape({
    RM37: PropTypes.string.isRequired
  })
};

export default Event;