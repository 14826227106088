import React from 'react';

import Header from './Header';
import Footer from './Footer';

import UserHelper from 'helpers/UserHelper';

const Layout = ({ children }) => {
    let isAuthenticated = UserHelper.isAuthenticated();

    return (
        <>
            {isAuthenticated && <Header />}

            <div className="content">
                {children}
            </div>
            
            {isAuthenticated && <Footer />}
        </>
    );
};

export default Layout;