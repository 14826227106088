import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner, Alert } from 'react-bootstrap';

import { Identification } from 'components/Employee';
import HDivider from 'components/Layout/HDivider';
import Filter   from 'components/Layout/Filter';
import FilterString   from 'components/Layout/FilterString';
import FilterMultiple   from 'components/Layout/FilterMultiple';

import MedicalCommunity   from './MedicalCommunity';
import Assistance   from './Assistance';
import Relationship from './Relationship';
import General      from './General';
import ViewLog      from './ViewLog';
import Efficiency      from './Efficiency';
import AssistanceRX      from './AssistanceRX';
import AssistanceAN      from './AssistanceAN';

import ToastAgreement from '../../components/Layout/ToastAgreement';

import IndicatorService from 'services/IndicatorService';
import IndicatorHelper  from 'helpers/IndicatorHelper';

import UserDataNotFound from 'components/Errors/UserDataNotFound';

const Loading = () => (
    <div className="loading animate__animated animate__fadeIn">
        <Spinner animation="border" role="status" size="lg" variant="primary">
            <span className="sr-only">Carregando...</span>
        </Spinner>
    </div>
);


const Dashboard = () => {
    let [period, setPeriod]   = useState(new Date().getFullYear());
    let [loading, setLoading] = useState(false);
    let [data,  setData]      = useState({});
    let [error, setError]     = useState(false);
    let [show, setShow] = useState(true);
    //let [month, setMonth] = useState(new Date().toLocaleString('pt-br', { month: 'long' }).charAt(0).toUpperCase() + new Date().toLocaleString('pt-br', { month: 'long' }).slice(1));
    let [month, setMonth] = useState("Todos");
    let [monthsList, setMonthsList] = useState(["Janeiro","Fevereiro", "Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"]);
    let [group, setGroup] = useState("Todos");
    let [nullData, setNullData] = useState({
        "averageGrade": "N/A",
        "level": "N/A",
        "admittedAt": "N/A",
        "generalScore": "N/A",
        "RM01": "N/A",
        "RM02": "N/A",
        "RM03": "N/A",
        "RM04": "N/A",
        "RM05": "N/A",
        "RM06": "N/A",
        "RM07": "N/A",
        "RM08": [],
        "RM09": "N/A",
        "RM10": "N/A",
        "RM10_1": "N/A",
        "RM10_2": "N/A",
        "RM11": "N/A",
        "RM12": "N/A",
        "RM13": [],
        "RM14": "N/A",
        "RM15": "N/A",
        "RM16": "N/A",
        "RM17": "N/A",
        "RM18": "N/A",
        "RM19": "N/A",
        "RM20": [],
        "RM21": "N/A",
        "RM22": "N/A",
        "RM23": "N/A",
        "RM24": "N/A",
        "RM25": "N/A",
        "RM26": "N/A",
        "RM27": "N/A",
        "RM28": "N/A",
        "RM29": "N/A",
        "RM31": "N/A",
        "RM32": "N/A",
        "RM33": "N/A",
        "RM34": "N/A",
        "RM35": "N/A",
        "RM36": "N/A",
        "RM37": "N/A",
        "RM38": "N/A",
        "RM39": "N/A",
        "RM40": "N/A",
        "RM41": "N/A",
        "RM42": "N/A",
        "RM30": [],
        "radiologyRMs":[]
    });

    const handleChangeFilter = async (selected) => {
        if(period === selected){
            return;
        }

        setPeriod(selected);
        return await loadData(selected);
    };

    const handleChangeMonth = (selected) => {
        //esse console.log vem desatualizado
        //console.log("Lista de Meses selecionados",selected);
        
        setMonthsList(selected);

        if(month == "Todos" && selected.length == 12){
            return;
        }else if(month == selected.length + " meses"){
            return;
        }else if(selected.length == 12){
            setMonth("Todos");
        }else if(selected.length == 0){
            setMonth("Nenhum");
        }else{
            setMonth(selected.length + " meses");
        }
    }

    const handleChangeGroup = selected => {
        setGroup(selected);
    }

    const loadData = async (period) => {
        try{
            setLoading(true);
            let { data } = await IndicatorService.all(period);

            data = IndicatorHelper.parse(data);

            if(data.indicators || !data.periods){
                setLoading(false);
            }

            setData(data);
            //console.log("evaluationType",data.evaluationType)
        }catch(e){
            setLoading(false);
            setError(true);
        }
    };

    const initialize = () => {
        loadData(period);
    };

    useEffect(initialize, []);

    return (
        <div className="dashboard">
            <Container>
                <Alert className="notification" key={'primary'} variant={'primary'} show={show} onClose={() => setShow(false)} dismissible>
                    IMPORTANTE: Informações sob validação!
                </Alert>

                <Row className="identification-row">
                    {data.employee &&
                        <Col className="animate__animated animate__fadeIn">
                            <Identification employee={data.employee} />
                        </Col>
                    }
                </Row>

                {data.periods && 
                    <Row className="animate__animated animate__fadeIn">
                        <Col sm="3"></Col>
                        <Col sm="2">
                            {data.evaluationType=='Radiologista'?
                            <FilterMultiple
                                clickedItem={month}
                                items={["Todos","Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"]}
                                onChange={handleChangeMonth}
                            />:null}
                        </Col>
                        <Col sm="2">
                            <Filter
                                current={period}
                                periods={data.periods}
                                onChange={handleChangeFilter}
                            />
                        </Col>
                        <Col sm="2">
                            {data.evaluationType=='Radiologista'?
                            <FilterString
                                clickedItem={group}
                                items={data}
                                onChange={handleChangeGroup}
                            />:null}
                        </Col>
                        <Col sm="3"></Col>
                    </Row>
                }

                {data.indicators &&
                    <>
                        <Row className="animate__animated animate__fadeIn">
                            <Col>
                                <h3 className="page-brand">Comunidade Médica</h3>
                            </Col>
                        </Row>
                        <HDivider evaluationType={data.evaluationType} 
                        levelAnestesista={data.evaluationType=="Anestesista"?data.anesthesiaRMs[0].cm_level.split('_')[1]:null} 
                        levelRadiologista={data.radiologyRMs.length>0 && data.evaluationType=="Radiologista"?data.radiologyRMs[0].cm_level.split('-')[1]:null} 
                        levelGeral={data.evaluationType=="Geral"?data.indicators.level:null}/>
                    </>
                }
            </Container>

            <Container className="container-extends indicators">
                {loading && <Loading />}




                {data.averageGrade && data.indicators && data.evaluationType=='Radiologista'?
                    <div className="animate__animated animate__fadeIn">
                        <MedicalCommunity scores={data.thisYearDataExist==false||data.isReleased==null?nullData:data.indicators} radiologyScore={data.radiologyRMs} grade={data.thisYearDataExist==false||data.isReleased==null?nullData.averageGrade:data.averageGrade} evaluationType={data.evaluationType}/>
                    </div>
                    :null
                }
                
                {data.anesthesiaRMs && data.evaluationType=='Anestesista'&&data.isReleased!=null?
                    <div className="animate__animated animate__fadeIn">
                        <MedicalCommunity scores={data.anesthesiaRMs[0]} evaluationType={data.evaluationType}/>
                    </div>
                    :null
                }

                {data.indicators && data.evaluationType=='Geral'&&data.isReleased!=null?
                    <div className="animate__animated animate__fadeIn">
                        <MedicalCommunity scores={data.indicators}/>
                    </div>
                    :null
                }

                {monthsList && data.radiologyRMs && data.productivity && data.prodPerGroup && data.evaluationType=='Radiologista'?
                    <div className="animate__animated animate__fadeIn">
                        <Efficiency scores={data.radiologyRMs} prodPerGroup={data.prodPerGroup} tableData={data.productivity} monthsList={monthsList} group={group}/>
                    </div>:null
                }

                {monthsList && data.radiologyRMs && data.evaluationType=='Radiologista'?
                    <div className="animate__animated animate__fadeIn">
                        <AssistanceRX scores={data.radiologyRMs} evaluationType={data.evaluationType} monthsList={monthsList} group={group}/>
                    </div>:null
                }
                {data.indicators && data.evaluationType=='Geral'&&data.isReleased!=null?
                    <div className="animate__animated animate__fadeIn">
                        <Assistance scores={data.indicators} />
                    </div>:null
                }

                {data.anesthesiaRMs && data.evaluationType=='Anestesista'&&data.isReleased!=null?
                    <div className="animate__animated animate__fadeIn">
                        <AssistanceAN scores={data.anesthesiaRMs} />
                    </div>:null
                }
                
                {data.indicators &&
                    <div className="animate__animated animate__fadeIn">
                        <Relationship scores={data.thisYearDataExist==false && data.evaluationType=="Radiologista"||data.isReleased==null?nullData:data.indicators} />
                        <General scores={data.thisYearDataExist==false && data.evaluationType=="Radiologista"||data.isReleased==null?nullData:data.indicators} />
                    </div>
                }


                {error && <UserDataNotFound />}
            </Container>

            {data.indicators && <ViewLog period={period} />}


            {
                <ToastAgreement scores={data.acceptTerms}/>
            }

        </div>
    );
};

export default Dashboard;
