import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/EditionIndex.png';


const ProdPerAnesthesyPerPeriod = ({ scores }) => {

  return (
    <div className="prodPerAnesthesyPerPeriod metrics-group" style={{width: 280}}>
      <Metric 
        title="Produtividade média por anestesista por período"
        image={Icon}
        scores={[
          {
            value: `${scores.rm_57}`
          }
        ]}
        info={{
          tooltip:'Média de anestesias realizadas por anestesistas no período selecionado.'
        }}
      />
    </div>
  );
};

export default ProdPerAnesthesyPerPeriod;