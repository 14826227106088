import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { ToggleButton } from 'react-bootstrap';
import { useState } from 'react';

import { ReactComponent as Calendar } from 'assets/images/icons/calendar-alt.svg'
import Filter from './Filter';


const FilterMultiple = ({ clickedItem, items, onChange }) => {

    const [checkedList, setCheckedList] = useState({
        "Todos":true,
        "Janeiro":true,
        "Fevereiro":true,
        "Março":true,
        "Abril":true,
        "Maio":true,
        "Junho":true,
        "Julho":true,
        "Agosto":true,
        "Setembro":true,
        "Outubro":true,
        "Novembro":true,
        "Dezembro":true
    });

    function selectTodos(boolean){
        let listaRefatorada = checkedList;

        items.map((mes)=>{
            //atualiza para true ou false o valor de todos os objetos
            setCheckedList(checkedList => ({
                ...checkedList,
                [mes]: boolean
            }));
            //atualiza para true ou false o valor 'dummy' de todos os objetos em tempo real
            listaRefatorada = {
                ...listaRefatorada,
                [mes]: boolean
            };

        })

        let listaMeses = [];
        //filtra o objeto com todos os meses = true e adiciona os nomes dos meses em uma lista
        Object.keys(listaRefatorada).map((mes)=>{
            if(listaRefatorada[mes] == true && mes!="Todos"){
                listaMeses.push(mes)
            }
        })

        return onChange(listaMeses);
    }
    
    const handleChange = (e) =>{
        //isso que fará as informações irem pro front
        
        let id = e.target.id;
        
        if(id == "Todos"){
            setCheckedList(checkedList => ({
                ...checkedList,
                [id]: !checkedList[id]
            }));
            //se marcar todos, marca todos meses como true
            if(!checkedList[id]){
                selectTodos(true);

            }else{
               selectTodos(false);
            }
        }else{
            setCheckedList(checkedList => ({
                ...checkedList,
                [id]: !checkedList[id]
            }));
    
            //conserta delay de atualização do valor do react
            let listaRefatorada = checkedList;
            listaRefatorada = {
                ...listaRefatorada,
                [id]: !listaRefatorada[id]
            };
    
            let listaMeses = [];
            //adiciona na listaMeses apenas os meses que estiverem como True
            Object.keys(listaRefatorada).map((mes)=>{
                if(listaRefatorada[mes] != undefined && listaRefatorada[mes] == true && mes!="Todos"){
                    listaMeses.push(mes)
                }
            })
    
            //se o valor for null, desmarca a opção "Todos"
            if(checkedList[id]){
                setCheckedList(checkedList => ({
                    ...checkedList,
                    ["Todos"]: false
                }));
            }
            //se todos valores forem true, marca a opção "Todos"
            if(listaMeses.length == 12){
                setCheckedList(checkedList => ({
                    ...checkedList,
                    ["Todos"]: true
                }));
            }

            return onChange(listaMeses);
        }
        
    }

    return (
        <div className="filter filter-periods text-center">
            <Dropdown>
                <Dropdown.Toggle
                    variant="outline-primary"
                    id="items"
                    size="lg"
                >
                    <Calendar alt="Item"/> {clickedItem}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {items.map((singleItem)=>
                        <Form.Check type="checkbox" label={singleItem} key={singleItem} id={singleItem} value={singleItem} checked={checkedList[singleItem]} onChange={handleChange} />
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
  }

  export default FilterMultiple;