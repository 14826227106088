import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/supervision.png';

const MedicalResidencySupervision = ({ scores }) => {
  return (
    <div className="medical-residency medical-residency-supervision metrics-group">
      <Metric
        title="Supervisão de Programa de Residência Médica"
        image={Icon}
        scores={[
          {
            value: scores.RM40
          }
        ]}
        info={{
            tooltip: '* Este indicador representa o profissional formalmente responsável pelo programa de residência médica, devidamente registrado junto à COREME'
        }}
      />
    </div>
  );
};

MedicalResidencySupervision.propTypes = {
  scores: PropTypes.shape({
    RM40: PropTypes.string.isRequired
  })
};

export default MedicalResidencySupervision;
