import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/communication.png';

const Description = (
  <>
    Avaliação da percepção dos pacientes sobre a comunicação com o corpo clínico <b>(HCAPS)</b>.
  </>
);

const Communication = ({ scores }) => {
  return (
    <div className="communication metrics-group">
      <Metric 
        title="Comunicação"
        description={Description}
        image={Icon}
        scores={[
          {
            value: scores.RM03
          }
        ]}
      />
    </div>
  );
};

Communication.propTypes = {
  scores: PropTypes.shape({
    RM03: PropTypes.string.isRequired
  })
};

export default Communication;