'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Metric from './Metric';
import Icon from 'assets/images/icons/orientation-2.png';

const Orientation = ({ scores }) => {
  return (
    <>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip id="button-tooltip">Em construção.</Tooltip>}
      >
        <div className="coordination-lato-sensu metrics-group" style={{opacity: "0.6"}}>
          <Metric
            title="Coordenação de Pós Graduação Lato Sensu, de Programas de Aprimoramento e Aulas Ministradas Nestes Programadas"
            image={Icon}
            scores={[
              {
                title: 'Coordenação',
                value: scores.RM35
              },
              {
                title: 'Aulas Ministradas',
                value: scores.RM36
              }
            ]}
          />
        </div>
      </OverlayTrigger>
    </>
  );
};

Orientation.propTypes = {
  scores: PropTypes.shape({
    RM35: PropTypes.string.isRequired,
    RM36: PropTypes.string.isRequired,
  })
};

export default Orientation;
