import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/AchadoCritico.png';


const CriticalFinding = ({ scores }) => {

  return (
    <div className="criticalFinding metrics-group" style={{width: 250}}>
      <Metric 
        title="Achado Crítico"
        image={Icon}
        scores={[
          {
            value: `${scores.rm_achado_porc || "N/A"}⠀Casos`
          },
          {
            value: ``
          },
          {
            value: `${scores.rm_achado || "N/A"}`
          }
        ]}
      />
    </div>
  );
};

export default CriticalFinding;