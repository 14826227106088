import React from 'react';
import { Redirect } from 'react-router-dom'

import UserHelper  from 'helpers/UserHelper';

const SignOut = () => {
    UserHelper.purge();

    return (
        <Redirect to="/users/sign_in" />
    );
};

export default SignOut;