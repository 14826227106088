import React, { useEffect } from 'react';

import LogService from 'services/LogService';

const ViewLog = ({ period }) => {

    const put = () => {
        LogService.put(period);
    }

    useEffect(put, [period]);

    return <></>;
};

export default ViewLog;