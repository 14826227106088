import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/coordination.png';

const MedicalResidency = ({ scores }) => {
  return (
    <div className="medical-residency medical-residency-coordination metrics-group">
      <Metric
        title="Coordenação de Programa de Residência Médica"
        image={Icon}
        scores={[
          {
            value: scores.RM38
          }
        ]}
        info={{
            tooltip:'* Este indicador representa profissionais que prestam suporte operacional à supervisão do programa de residência médica, coordenando as atividades do programa.'
        }}
      />
    </div>
  );
};

MedicalResidency.propTypes = {
  scores: PropTypes.shape({
    RM38: PropTypes.string.isRequired
  })
};

export default MedicalResidency;
