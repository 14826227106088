import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/multidao.png';


const MultidisiplinaryMeetings = ({ scores }) => {

  return (
    <div className="multidisciplinaryMeetings metrics-group" style={{maxWidth: 220}}>
      <Metric 
        title="Reuniões Multidisciplinares"
        image={Icon}
        scores={[
          {
            value: `${scores.rm_51 || "N/A"}`
          },
          {
            value: `${scores.rm_51_porc || "N/A"}%`
          }
        ]}
      />
    </div>
  );
};

export default MultidisiplinaryMeetings;