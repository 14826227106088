import React from 'react';
import PropTypes from 'prop-types';

import { Row } from 'react-bootstrap';

import MultidisiplinaryMeetings from '../../components/Scores/MultidisciplinaryMeetings';
import ProdPerPeriod from '../../components/Scores/ProdPerPeriod';
import PeriodsPerWeek from '../../components/Scores/PeriodsPerWeek';
import ProdPerGroup from '../../components/Scores/ProdPerGroup';

import Icon from 'assets/images/icons/hospital.png';
import TableBootstrap from '../../components/Layout/TableBootstrap';




const Efficiency = ({ scores, prodPerGroup, tableData, monthsList, group}) => {
    

    let prodGpMesMedia = {};
    if(group == "Todos" && monthsList.length > 0){
        prodGpMesMedia = {prod_final_nao_rx_grupo:"MaisDeUmGrupo",prod_final_rx_grupo:"MaisDeUmGrupo"}
    }else if(monthsList.length > 0){
        let prodGp = prodPerGroup.filter(prodGrupo =>{
            return prodGrupo.grupo_0 == group;
        })
        let prodGpMes = prodGp.filter(prodGrupoMes=>monthsList.includes(prodGrupoMes.mes));
        let prodGpMesSomado = prodGpMes.reduce((previousProd, nextProd)=>{
            return {prod_final_nao_rx_grupo:previousProd.prod_final_nao_rx_grupo + nextProd.prod_final_nao_rx_grupo, prod_final_rx_grupo:previousProd.prod_final_rx_grupo + nextProd.prod_final_rx_grupo};
        })
        prodGpMesMedia = {prod_final_nao_rx_grupo:(prodGpMesSomado["prod_final_nao_rx_grupo"]/prodGpMes.length).toFixed(2),prod_final_rx_grupo:(prodGpMesSomado["prod_final_rx_grupo"]/prodGpMes.length).toFixed(2)}
    }else{
        prodGpMesMedia = {prod_final_nao_rx_grupo:"0",prod_final_rx_grupo:"0"}
    }

    //filtra os meses selecionados
    //let monthsList = ["Janeiro","Fevereiro","Março"];
    let newMonthScores = [];
    let newScores = [];
    scores.map(score=>{
        if(monthsList.includes(score.mes)){
            newMonthScores.push(score);
        }
    })
    //filtra por grupo
    newMonthScores.map(score=>{
        if(group=='Todos'){
            newScores.push(score);
        }else if(group.includes(score.grupo_0)){
            newScores.push(score);
        }
    })

    let finalScore = {};

        
    let listaTotal = [];
    let listaMedia = {};
    let listaKeys = scores.length > 0?Object.keys(scores[0]).slice(4):[];

    for(let i=0;i<listaKeys.length; i++){
        listaTotal.push(newScores.reduce((list, obj)=>list + parseFloat(obj[listaKeys[i]]),0))
    }
    //console.log("LISTATOTAL EFFICIENCY",listaTotal);

    for(let i=0;i<listaTotal.length; i++){
        listaMedia[listaKeys[i]] = (parseFloat(listaTotal[i]/newScores.length).toFixed(2))
        if(listaMedia[listaKeys[i]].split(".")[1] == "00"){
            listaMedia[listaKeys[i]] = listaMedia[listaKeys[i]].split(".")[0];
        }
    }

    if(monthsList.length == 0){
        for(let i=0;i<listaKeys.length; i++){
            listaMedia[listaKeys[i]] = 0;
        }
    }

    finalScore = listaMedia;

    Object.keys(finalScore).map((key)=>{
        if(finalScore[key] != NaN && finalScore[key] != null && finalScore[key] != undefined){
            finalScore[key] = finalScore[key].toString().replace(".",",")
        }
    });

    Object.keys(prodGpMesMedia).map((key)=>{
        if(prodGpMesMedia[key] != NaN && prodGpMesMedia[key] != null && prodGpMesMedia[key] != undefined){
            prodGpMesMedia[key] = prodGpMesMedia[key].toString().replace(".",",")
        }
    });

    //console.log("LISTA MEDIA", finalScore);
       
    return (
        <div className="scores scores-assistance">
            <h2>
                Indicadores de Eficiência
            </h2>
            <Row className="divider justify-content-md-center">
                <div className="general-group">
                    <div className="logo-box">
                        <TableBootstrap tableData={tableData} monthsList={monthsList} group={group} />
                    </div>
                </div>
            </Row>
            <Row className="divider justify-content-md-center">
                <div className="general-group">
                    <div className="logo-box">
                        <MultidisiplinaryMeetings scores={finalScore} />
                        <ProdPerPeriod scores={finalScore} />
                        <ProdPerGroup scores={prodGpMesMedia} />
                        <PeriodsPerWeek scores={finalScore} />
                    </div>
                </div>
            </Row>
        </div>
    );
};

Efficiency.propTypes = {
    scores: PropTypes.array.isRequired
};

export default Efficiency;
