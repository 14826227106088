import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

const ToastAgreement = ({scores}) => {
  const [showToast, setShowToast] = useState(true);

  return (
    <>
    {scores === null ?
      <Row>
        <div className="p-3" style={{position: "fixed",bottom: 20,left:"37%",margin: 0}}>
          <Toast onClose={() => setShowToast(false)} show={showToast} >
            <Toast.Body>
              <p style={{textAlign:"center"}}>Este portfolio é um recorte individual de aspectos relativos a sua pratica assistencial e ao seu relacionamento com o Hospital Sírio Libanês</p>
              <p style={{textAlign:"center"}}><Button onClick={() => setShowToast(false)}>De acordo</Button></p>
            </Toast.Body>
          </Toast>
        </div>
      </Row>
      :null
    }
    </>
  );
}

export default ToastAgreement;