import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/ScientificAcademic.png';


const ScientificAcademicParticipation = ({ scores }) => {

  return (
    <div className="scientificAcademicParticipation metrics-group" style={{width: 280}}>
      <Metric 
        title="Participação acadêmico-científica"
        image={Icon}
        scores={[
          {
            value: `${scores.rm_52 || "N/A"}`
          },
          {
            value: `${scores.rm_52_porc || "N/A"}%`
          }
        ]}
      />
    </div>
  );
};

export default ScientificAcademicParticipation;