import React from 'react';

import Metric from 'components/Scores/Metric';
import Icon from 'assets/images/icons/PontuacaoRadiologia.png';


const AverageGrade = ({ grade }) => {

  return (
    <div className="averageGrade metrics-group" style={{width: 250}}>
      <Metric 
        title="Pontuação Radiologia"
        image={Icon}
        scores={[
          {
            value: `${grade.toString().replace(".",",")}`
          }
        ]}
      />
    </div>
  );
};

export default AverageGrade;