import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap';

import { ReactComponent as Calendar } from 'assets/images/icons/calendar-alt.svg'
const Filter = ({ current, periods, onChange }) => {
    periods = periods.sort((a, b) => b -a );

    const handleSelectPeriod = (period) => {
        return onChange(parseInt(period, 10));
    };

    const resolverPeriod = () => {
        if(periods.indexOf(current) === -1){
            handleSelectPeriod(periods[0]);
        }
    };

    useEffect(resolverPeriod, []);

    return (
        <div className="filter filter-periods text-center">

            <Dropdown>
                <Dropdown.Toggle
                    variant="outline-primary"
                    id="periods"
                    size="lg"
                >
                   <Calendar alt="Período"/> {current}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {periods.map((year) =>
                        <Dropdown.Item
                            onSelect={handleSelectPeriod}
                            eventKey={year}
                            key={year}
                            active={year === current}
                        >
                            {year}
                        </Dropdown.Item>
                )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

Filter.propsTypes = {
    current:  PropTypes.string.isRequired,
    periods:  PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

export default Filter;