import React from 'react';
import PropTypes from 'prop-types';

import { Row } from 'react-bootstrap';

import {
    ENPS,
    NPS,
    Communication,
    Hygiene
} from 'components/Scores';

import Icon from 'assets/images/icons/hospital.png';


const General = ({ scores, year }) => {

    return (
        <div className="scores scores-general">
            <h2>
                Indicadores Gerais
            </h2>

            <Row className="justify-content-md-center divider">
                <div className="general-group">
                    <div className="logo-box">
                        <img src={Icon} alt="Corpo Clínico ícone" />
                        <p className="title">Indicadores Gerais e do Corpo Médico</p>
                    </div>
                </div>

                <ENPS scores={scores} />
                <NPS scores={scores} />
                <Communication scores={scores} />
                <Hygiene scores={scores} />
            </Row>
        </div>
    );
};

General.propTypes = {
    scores: PropTypes.object.isRequired
};

export default General;
