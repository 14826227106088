import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/like.png';


const Description = (
  <>
    <b>Net Promoter Score:</b> Índice de recomendação atribuído ao Hospital Sírio-Libanês pelos médicos.
  </>
);

const ENPS = ({ scores }) => {
  return (
    <div className="enps metrics-group">
      <Metric 
        title="E-NPS"
        description={Description}
        image={Icon}
        scores={[
          {
            value: scores.RM02
          }
        ]}
      />
    </div>
  );
};

ENPS.propTypes = {
  scores: PropTypes.shape({
    RM02: PropTypes.string.isRequired
  })
};

export default ENPS;