import React from 'react';
import PropTypes from 'prop-types';
import Metric from 'components/Scores/Metric';

import GraphIcon from 'assets/images/icons/socre4.png';

const GeneralScore = ({ score }) => {
    return (
        <div className={`general-score metrics-group medical-community`}>
            <Metric scores={[{value: score}]} title="Pontuação Geral" image={GraphIcon}/>
        </div>
    );
};

GeneralScore.propTypes = {
    score: PropTypes.string
};

export default GeneralScore;
