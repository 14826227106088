import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap';
import { useState } from 'react';

import { ReactComponent as Calendar} from 'assets/images/icons/grupo_icone.svg'
import Filter from './Filter';


const FilterString = ({ clickedItem, items, onChange }) => {

    let newItemsList = [];
    if(items.hasOwnProperty('radiologyRMs') && items.radiologyRMs != null){
        items.radiologyRMs.map((rm)=>{
            if(!newItemsList.includes(rm.grupo_0)){
                newItemsList.push(rm.grupo_0)
            }
        });
    
        items =['Todos'].concat(newItemsList);
    }else{
        items = ['Geral'];
    }

    //items = ['Geral', 'RX','Teste'];

    const handleChange = async (e) =>{
        //isso que fará as informações irem pro front
        return onChange(e);
    }
  
    return (
        <div className="filter filter-periods text-center">
            <Dropdown>
                <Dropdown.Toggle
                    variant="outline-primary"
                    id="items"
                    size="lg"
                >
                    <Calendar alt="Item" style={{ transform:'rotate(180deg)' }} /> {clickedItem}
                    
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {items.map((singleItem)=>

                        <Dropdown.Item 
                            onSelect={handleChange}
                            eventKey={singleItem}
                            key={singleItem}
                            active={singleItem === clickedItem}
                        >
                            {singleItem}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
  }

  export default FilterString;