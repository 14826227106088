import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/like.png';

const Description = (
  <>
    <b>Net Promoter Score:</b> Índice de recomendação atribuído ao Hospital Sírio-Libanês pelos pacientes.
  </>
);

const NPS = ({ scores }) => {
  return (
    <div className="nps metrics-group">
      <Metric 
        title="NPS"
        description={Description}
        image={Icon}
        scores={[
          {
            value: scores.RM01
          }
        ]}
      />
    </div>
  );
};

NPS.propTypes = {
  scores: PropTypes.shape({
    RM01: PropTypes.string.isRequired
  })
};

export default NPS;