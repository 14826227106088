import axios from 'axios';

import config from 'config/api';
import UserHelper from 'helpers/UserHelper';

/**
 * Client para envio das requisições
 *
 * @type { Axios }
 */
const client = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'X-Api-Key':     config.apiKey,
        'Channel':       config.channel
    }
});

/**
 * Proxy para serviços de API
 *
 * @pubic
 */
class Service{

    /**
     * Porxy para envio de requsição POST
     *
     * @param  { String } URL
     * @param  { Object } data
     * @param  { Object } headers
     * @return { Promise }
     * 
     */
    static post(url, data, headers = {}){
        headers = this._headers(headers);
        return client.post(url, data, { headers });
    }

    /**
     * Porxy para envio de requsição GET
     *
     * @param  { String } URL
     * @param  { Object } queryString
     * @param  { Object } headers
     * @return { Promise }
     * 
     */
    static get(url, query = {}, headers = {}){
        headers = this._headers(headers);
        return client.get(url, { params: query, headers });
    }

    /**
     * Adiciona Access Token no HEADER
     *
     * @param  { Object } headers
     * @return { Object }
     *
     */
    static _headers(headers){
        headers.Authorization = UserHelper.getAuthorization();
        return headers;
    }
}

export default Service;