import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/publication.png';

const Publication = ({ scores }) => {
  return (
    <div className="publication metrics-group">
      <Metric 
        title="Publicações Científicas Sírio-Libanês"
        image={Icon}
        scores={[
          {
            value: scores.RM33
          }
        ]}
      />
    </div>
  );
};

Publication.propTypes = {
  scores: PropTypes.shape({
    RM33: PropTypes.string.isRequired
  })
};

export default Publication;