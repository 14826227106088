import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/calendar.png';


const PeriodsPerWeek = ({ scores }) => {

  return (
    <div className="periodsPerWeek metrics-group" style={{maxWidth: 220}}>
      <Metric 
        title="Períodos por semana"
        image={Icon}
        scores={[
          {
            value: `${scores.rm_44 || "N/A"}`
          },
          {
            value: `${scores.rm_44_porc || "N/A"}%`
          }
        ]}
      />
    </div>
  );
};

export default PeriodsPerWeek;