import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import GraphIcon from 'assets/images/icons/graph.png';

const Hospitalized = ({ scores }) => {
  return (
    <div className="hospitalized metrics-group">
      <Metric 
        title="Pacientes internados"
        image={GraphIcon}
        scores={[
          {
            title: 'Ano Anterior',
            value: scores.RM07
          },
          {
            title: 'Ano Vigente',
            value: scores.RM06
          },
          {
            title: 'Especialidade',
            subtitle: 'Ano Anteior',
            value: scores.RM09,
            className: 'circle'
          },
          {
            title: 'Especialidade',
            subtitle: 'Ano Vigente',
            value: scores.RM10,
            className: 'circle'
          },
          {
            title: 'Sírio-Libanês',
            value: scores.RM11,
            className: 'circle'
          }
        ]}
      />
    </div>
  );
};

Hospitalized.propTypes = {
  scores: PropTypes.shape({
    RM06: PropTypes.string.isRequired,
    RM07: PropTypes.string.isRequired,
    RM09: PropTypes.string.isRequired,
    RM10: PropTypes.string.isRequired,
    RM11: PropTypes.string.isRequired
  })
};

export default Hospitalized;