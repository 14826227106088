import IndicatorHelper  from 'helpers/IndicatorHelper';

export default (data, title = null) => ({
    chart: {
        height: '84%',
        style: {
            fontFamily: 'Roboto',
            'textTransform': 'uppercase'
        }
    },
    title: {
        text: title,
        style: {
            'fontSize': '14px'
        }
    },
    xAxis: [
        {
            categories: data.map(i => i.name),
            crosshair: true,
        }
    ],
    tooltip: {
        formatter: function(i) {
            let point = this.points.map((point) => {
                return (
                    `<tr>` +
                        `<td class="icon"><span style="background: ${point.series.color};"></span></td>` +
                        `<td><span class="title"> ${point.series.name}</td><span>` +
                        `<td class="value"><b>${IndicatorHelper.fixValue(point.y)}</b></td>` +
                    `</tr>`
                );
            });

            return (
                `<table class="legend-graph-table">` +
                    `<tr>` +
                        `<td></td>` +
                        `<td class="title-box" colspan="2" >${this.points[0].x}</td>` +
                    `</tr>` +
                    point.join('') +
                `</table>`
            );
        },
        shared: true,
        useHTML: true,
        backgroundColor: '#FFFFFF',
        borderColor:     '#ECF0F1',
        borderWidth:     '0'
    },
    yAxis: [
        {
            min: 0,
            gridLineWidth: 0,
            title: {
                text: null
            }
        },
        {
            title: {
                text: 'Quantidade',
            },
            opposite: true
        }
    ],
    series: [
        {
            type: 'column',
            name: 'Média de Receita - R$ Milhares',
            data: data.map(i => i.revenue),
            color: '#448ecf',

        },
        {
            type: 'column',
            name: 'Média de Custo - R$ Milhares',
            data: data.map(i => i.cost),
            color: '#8977b4'

        },
        {
            type: 'spline',
            yAxis: 1,
            name: 'Quantidade',
            data: data.map(i => i.quantity),
            color: '#b6e18d',
            lineWidth: 0

        }
    ],
    credits: {
        enabled: false
    }
});
