import React from 'react';
import { Redirect, Route } from "react-router-dom";

import UserHelper from 'helpers/UserHelper';

const Rules = (props) => {
    if(!UserHelper.isAuthenticated()){
        return (
            <Redirect to={{
                    pathname: '/users/sign_in',
                    state: {
                        from: props.location
                    }
                }}
            />
        );
    }

    const Component = props.component;
    return <Component {...props} />
};

const PrivateRoute = (props) => {
    const component = props.component;

    return (
        <Route
            {...props}
            component={props => <Rules component={component} {...props}/>}
        />
    );
};

export default PrivateRoute;