import { Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import Dashboard from 'containers/Dashboard';
import Auth      from 'containers/Auth';

const routes = [
    {
        path:      '/',
        name:      'Dashboard',
        component: Dashboard,
        route:     PrivateRoute
    },
    {
        path:      '/users/sign_out',
        name:      'Sing In',
        component: Auth.SignOut,
        route:     PrivateRoute
    },
    {
        path:      '/users/sign_in',
        name:      'Sing In',
        component: Auth.SignIn,
        route:     Route
    }
];

export default routes;