import Service from './Service';
import api from 'config/api';

class LogService extends Service{

    /**
     * Cria um log de acesso para o periodo visualizado.
     *
     * @param  { String }
     * @return { Promise{Object} }
     * 
     */
    static put(period){
        //api.url = "https://e3fwoocfwj.execute-api.us-east-1.amazonaws.com/HML";
        let url = `${api.url}/v1/indicators/${period}/accept`;
        return this.post(url);
    }
}

export default LogService;