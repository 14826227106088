import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

import Metric from './Metric';
import Icon from 'assets/images/icons/tomb.png';

const Index = ({ data } ) => {
  return (
    <Table className="metric table-custom ml-3">
      <tbody>
        {data.map((item, i) => 
          <tr key={i}>
            <td className="title">{item.key}</td>
            <td className="value">{item.value}</td>
          </tr>
        )}  
      </tbody>
    </Table>
  );
};

const Mortality = ({ scores }) => {
  return (
    <div className="mortality metrics-group index-group">
      <Metric 
        title="Mortalidade e Risco de Óbito"
        image={Icon}
        scores={[
          {
            title: 'Taxa de Mortalidade',
            value: scores.RM19
          },
          {
            content: <Index data={scores.RM20} />
          },
          {
            title: 'Especialidade',
            value: scores.RM21,
            className: 'circle'
          }
        ]}
      />
    </div>
  );
};

Mortality.propTypes = {
  scores: PropTypes.shape({
    RM19: PropTypes.string.isRequired,
    RM20: PropTypes.array.isRequired,
    RM21: PropTypes.string.isRequired
  })
};

export default Mortality;