import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';

import {
    Hospitalized,
    Ambulatory,
    Diagnostic,
    Prophylaxis,
    MedicalRecord,
    Gravity,
    HospitalizationStay,
    Mortality,
    Cost
} from 'components/Scores';

const Assistance = ({ scores }) => {

    return (
        <div className="scores scores-assistance">
            <h2>
                Indicadores de Prática Assistencial
            </h2>

            <Row className="divider justify-content-md-center">
                <Hospitalized scores={scores} />
                <Ambulatory scores={scores} type="advanced" />
                <Ambulatory scores={scores} type="surgery" />
            </Row>

            <Row className="divider justify-content-md-center">
                <Diagnostic scores={scores} />
                <Prophylaxis scores={scores} />
                <MedicalRecord scores={scores} />
            </Row>

           <Row className="divider justify-content-md-center">
                <Col md="5">
                    <Row className="divider justify-content-md-end">
                        <Gravity scores={scores} />
                    </Row>
                    <Row className="divider  justify-content-md-end">
                        <HospitalizationStay scores={scores} />
                    </Row>
                    <Row className="divider  justify-content-md-end">
                        <Mortality scores={scores} />
                    </Row>
                </Col>
                <Cost scores={scores} />
            </Row>
        </div>
    );
};

Assistance.propTypes = {
    scores: PropTypes.object.isRequired
};

export default Assistance;
