import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

import Metric from './Metric';
import Icon from 'assets/images/icons/monitor.png';

const GravityIndex = ({ data } ) => {
  return (
    <Table className="metric table-custom ml-3">
      <tbody>
        {data.map((item, i) =>
          <tr key={i}>
            <td className="title">{item.key}</td>
            <td className="value">{item.value}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

const Gravity = ({ scores }) => {
  return (
    <div className="gravity metrics-group index-group">
      <Metric
        title="Gravidade"
        image={Icon}
        scores={[
          {
            title: 'ICM*',
            value: scores.RM12,
            tooltip: '*O Índice de Case Mix (ICM) resume a complexidade do paciente, ponderando número de saídas, idade, diagnósticos prévios e ' +
            'atuais, uso de quimio, radio ou hemoterapia, procedimentos cirúrgicos ou diagnósticos complexos (invasivos) e ainda ventilação ' +
            'mecânica. É um número decimal que tem significado apenas para comparação entre médicos, operadoras e hospitais.'
          },
          {
            content: <GravityIndex data={scores.RM13} />
          },
          {
            title: 'Especialidade',
            value: scores.RM14,
            className: 'circle'
          },
          {
            title: 'Sírio-Libanês',
            value: scores.RM15,
            className: 'circle'
          }
        ]}
      />

    </div>
  );
};

Gravity.propTypes = {
  scores: PropTypes.shape({
    RM12: PropTypes.string.isRequired,
    RM13: PropTypes.array.isRequired,
    RM14: PropTypes.string.isRequired,
    RM15: PropTypes.string.isRequired
  })
};

export default Gravity;
