import numeral from 'numeral';
import 'numeral/locales/pt-br';

numeral.locale('pt-BR');

/**
 * Indicadores com valor Sim ou Não.
 *
 * @type {String[]}
 */
const YES_OR_NO = ['RM04', 'RM33', 'RM34', 'RM35', 'RM36', 'RM37', 'RM38', 'RM39', 'RM40'];

class IndicatorHelper{
    /**
     * Faz as formatação dos indicadores
     *
     * @param  { Object } data
     * @return { Object }
     *
     */
    static parse(data){
        if(!data.indicators){
            return data;
        }

        data.employee   = this.employee(data.indicators);
        data.indicators = this.fixNumberValues(data.indicators);
        data.indicators = this.fixYesOrNo(data.indicators);
        data.indicators.admittedAt = parseInt(data.indicators.admittedAt, 10);
        data.indicators.level = data.indicators.level.replace(/CM_/, '');
        data.indicators.RM08 = this.fixDiagnostics(data.indicators.RM08);
        data.indicators.RM30 = this.fixCostAndRevenue(data.indicators.RM30);
        data.indicators.RM05 = data.indicators.RM05 ? parseInt(data.indicators.RM05, 10) : data.indicators.RM05;

        return data;
    }

    /**
     * Faz a formatação das informações do usuário
     *
     * @param { Object } data
     * @return { Object }
     *
     */
    static employee(data){
        return {
            name: data.name,
            clinical: {
                council: 'CRM',
                number:  data.CRM,
                state:   data.state,
                specialty: data.specialty
            }
        }
    }

    /**
     * Faz a formatação dos números
     *
     * @param  { Object } indicators
     * @param  { String[] }  toFix list of indicators to fix
     * @return { Object }
     *
     */
    static fixNumberValues(indicators, toFix = ['RM07', 'RM06', 'RM09', 'RM10', 'RM41', 'RM42', 'RM11']){
        toFix.forEach((indicator) => {
            indicators[indicator] = this.fixValue(indicators[indicator]);
        });

        return indicators
    }

    /**
     * Faz a formatação do numero.
     *
     * @param  { Number } value
     * @return { String }
     *
     */
     static fixValue(value){
         return numeral(value).format();;
     }

    /**
     * Faz a formtação dos diagnósticos
     *
     * @param  { Object[] } diagnostics
     * @return { Object[] }
     *
     */
    static fixDiagnostics(diagnostics){
        diagnostics = diagnostics.map(i => {
            i.value = numeral(i.value).value();
            return i;
        });

        diagnostics = diagnostics.sort((a, b) => b.value - a.value);
        return diagnostics.slice(0, 3);
    }

    /**
     * Faz a formatação dos dados do RM30 para apresentação no Grafico
     *
     * @param  { Object[] } data
     * @return { Object[] }
     *
     */
    static fixCostAndRevenue(data){
        data = data.map((i) => {
            return {
                name:     i.doctor,
                revenue:  parseFloat(i.avgRevenue),
                cost:     parseFloat(i.avgCost),
                quantity: i.quantity
            }
        });

        return data;
    }

    /**
     * Adiciona N/D para os indicadores que não tiverem valor.
     *
     * @param { Object } data
     * @return { Object }
     *
     */
    static fixNoData(data){
        let keys = Object.keys(data);

        keys.forEach((key) => {
            if(!/^RM/.test(key)){
                return;
            }

            if(!data[key] || data[key] === ''){
                data[key] = '‎‎‎ ';
            }
        });

        return data;
    }

    /**
     *
     * @param {Object} indicators
     * @return {Object}
     */
    static fixYesOrNo(indicators){
        YES_OR_NO.forEach(i => {
            indicators[i] = indicators[i] > 0 ? 'Sim' : 'Não';
        });

        return indicators;
    }
}

export default IndicatorHelper;
