import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/IndPeerReview.png';


const CNQ = ({ scores }) => {

  return (
    <div className="CNQ metrics-group" style={{width: 280}}>
      <Metric 
        title="Circunstâncias de risco"
        image={Icon}
        scores={[
          {
            value: `${scores.rm_58}`
          }
        ]}
        info={{
          tooltip:'Situações de risco relacionadas à não adesão às práticas de segurança, documentação incompleta e/ou questões comportamentais.'
        }}
      />
    </div>
  );
};

export default CNQ;