import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/prophylaxis.png';

const Prophylaxis = ({ scores }) => {
  return (
    <div className="prophylaxis metrics-group metrics-group">
      <Metric
        title="Profilaxia de Tromboembolismo Venoso"
        image={Icon}
        hint='&nbsp;'
        scores={[
          {
            title: 'Adesão às Recomendações \n (75% Esperado)',
            value: scores.RM28
          }
        ]}
      />
    </div>
  );
};

Prophylaxis.propTypes = {
  scores: PropTypes.shape({
    RM28: PropTypes.string.isRequired
  })
};

export default Prophylaxis;
