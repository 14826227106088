import React from 'react';
import { Row, Col } from 'react-bootstrap';


const HDivider = ({ levelAnestesista, levelGeral, levelRadiologista, evaluationType }) => {
    let level = 0

    console.log("evaluationType",evaluationType);
    console.log("LEVEL",levelGeral);
    console.log("LEVEL",levelRadiologista);
    console.log("LEVEL",levelAnestesista);
    

    switch (evaluationType) {
        case "Anestesista":
            level = levelAnestesista
            break;
        case "Radiologista":
            level = levelRadiologista
            break;
        case "Geral":
            level = levelGeral
            break;
        default:
            break;
    }

    console.log("LEVEL APÓS SWITCH",level);

    level = parseInt(level, 10);

    const isActive = (ref) => {
        if(ref === level) {
            return 'active';
        }

        return '';
    };

    return (
        <Row className="animate__animated animate__fadeIn">
            <Col xs={12}>
                <div className="h-divider colors-divider">
                    <span className="numbers">1</span>
                    <span className={`color color-one ${isActive(1)}`}></span>
                    <span className={`color color-two ${isActive(2)}`}></span>
                    <span className={`color color-three ${isActive(3)}`}></span>
                    <span className={`color color-four ${isActive(4)}`}></span>
                    <span className={`color color-five ${isActive(5)}`}></span>
                    <span className="numbers">5</span>
                </div>
            </Col>
        </Row>
    );
};

export default HDivider;
