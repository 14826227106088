import React from 'react';
import PropTypes from 'prop-types';

import Chart from './Charts/CostAndRevenue';
import { Info } from './Metric';
import Icon from 'assets/images/icons/currency.png';

const Cost = ({ scores }) => (
    <div className="cost metrics-group col-md-6 cost-and-revenue">
        <Info
            title="Custo e Receita"
            image={Icon}
            tooltip="* Este gráfico representa o volume de pacientes internados em seu nome (marcador verde), a média da receita faturada (barra azul) e a média do custo desses atendimentos (barra roxa). Observe que o gráfico permite que você se compare aos 10 maiores volumes da especialidade. Na legenda do eixo horizontal, observe que, ao lado do nome fictício de cada médico (respeitando o sigilo de informações), há o ICM - índice de case mix - dos pacientes deste profissional, permitindo que se leve em consideração este importante dado na comparação financeira."
        />

        <div className="details">
            <Chart data={scores.RM30} title="Média de Receita por Médico e Nº Saídas" />
            <div className="clearfix"></div>
        </div>

    </div>
);

Cost.propTypes = {
  scores: PropTypes.shape({
    RM30: PropTypes.array.isRequired
  })
};

export default Cost;
