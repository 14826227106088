import React from 'react';
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap';

import { ReactComponent as Exclamation } from 'assets/images/icons/exclamation-triangle-solid.svg';

const UserDataNotFound = () => {
    return (
        <div className="error user-data-not-found animate__animated animate__fadeIn">
            <span className="icon">
                <Exclamation />
            </span>

            <h5>Não foi possível localizar os dados de indicadores para o usuário.</h5>
            <Button 
                as={Link}
                to='/users/sign_out'
                size="sm"
                variant="outline-secondary"
                className="mt-3"
            >
                Sair
            </Button>
        </div>
    );
};

export default UserDataNotFound;