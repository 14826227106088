import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

import { Info, Score } from './Metric';
import Icon from 'assets/images/icons/diagnostic.png';

const Diagnostic = ({ scores }) => {
  return (
    <div className="diagnostic metrics-group index-group">
      {scores.RM08.length
        ? <Info title="Diagnósticos" image={Icon}/>
        : <Info title="Diagnósticos" image={Icon} hint='&nbsp;'/>
      }

      <div className="details">
        <div className="metrics">
            {scores.RM08.length
                ? <Table className="metric table-custom">
                    <tbody>
                    {scores.RM08.map((diagnostic, i) =>
                        <tr key={i}>
                            <td className="title">{diagnostic.key}</td>
                            <td className="value">{diagnostic.value}</td>
                        </tr>
                    )}
                    </tbody>
                  </Table>
                : <Score data={{value: 'N/A'}} />
            }
        </div>
      </div>

      <div className="clearfix"></div>
    </div>
  );
};

Diagnostic.propTypes = {
  scores: PropTypes.shape({
    RM08: PropTypes.array.isRequired
  })
};

export default Diagnostic;
