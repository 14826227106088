import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import LogoHSL from 'assets/images/logo-white.png';

const Header = () => {
    return (
        <footer>
            <Container>
                <div className="footer">
                    <Row className="justify-content-md-center">
                        <Col className="brand">
                            <img
                                className="logo logo-hsl"
                                src={LogoHSL}
                                alt="Logo Hospital Sírio Libanês"
                            />
                            
                        </Col>
                        <Col className="responser">
                            <p>Diretoria de Governança Clínica</p>
                        </Col>
                        <Col className="help">
                            <p className=" title">Serviço de apoio ao Médico</p>
                            <p>(11) 3394-1000</p>
                        </Col>
                    </Row>

                    <div className="clearfix"></div>
                </div>
            </Container>
        </footer>
    );
};

export default Header;