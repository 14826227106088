import Table from 'react-bootstrap/Table';
import React from 'react';


function TableBootstrap({tableData,monthsList,group}) {

  //let listaMeses = ["Janeiro","Fevereiro", "Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];
  //let listaMeses = ["Fevereiro", "Março", "Abril"];

  let listaMeses = monthsList;
  let newTableData = [];



  //INICIO ALTERACAO DE DADOS DA TABELA PARA SOMA DE VALORES DOS MESES RECEBIDOS
  let oldArray = {};
  let newArray = {};
  let sumArray = {};
  let objKeysCertos = ["do","int","mg","nm","pt","rm","rm","rx","tc","us","mes"];
  //let mesesSelecionados = ["Janeiro","Fevereiro"];
  //let listaGrupos = ["MSK","US"];
  let arrFinal = [];
  
  console.log("tableData", tableData);

  tableData.map(mes=>{
  
      let mesAtual = mes.mes;

      if(oldArray[mesAtual] == undefined && group.includes(mes.grupo_0)){
        oldArray[mesAtual] = mes;
        sumArray = oldArray;
      }
      if(group == 'Todos'){
        if(oldArray[mesAtual] == undefined){
          oldArray[mesAtual] = mes;
        }else{
          newArray[mesAtual] = mes;
          sumArray[mesAtual] = {};
          Object.keys(oldArray[mesAtual]).map(key=>{
            if(objKeysCertos.includes(key) && key!='mes'){
              sumArray[mesAtual][key] = oldArray[mesAtual][key] + newArray[mesAtual][key];
            }else if(key=='mes'){
              sumArray[mesAtual][key] = mesAtual;
            }
          })
        }
      }
  })

  //caso só exista um grupo, a lista deve permanecer a mesma
  if(Object.keys(sumArray).length == 0){
    sumArray = {...oldArray};
  }

  Object.keys(sumArray).map(mesSomadoAtual=>{
    arrFinal.push(sumArray[mesSomadoAtual]);
  })
  
  //console.log("ARRFINAL",arrFinal)

  //17/08 FINAL ALTERAÇÕES




  //filtra os meses pela listaMeses
  listaMeses.map((mes)=>{
    //tableData.map((row)=>{
    arrFinal.map((row)=>{
        if(Object.values(row).includes(mes)){
          newTableData.push(row);
        }
    })
  })

  //console.log("TABLE DATA",newTableData);

  let sumDO= 0;
  let sumINT= 0;
  let sumMG= 0;
  let sumNM= 0;
  let sumPT= 0;
  let sumRM= 0;
  let sumRX= 0;
  let sumTC= 0;
  let sumUS= 0;

/*
  //essa função foi movida para baixo, para reduzir número de loops
  newTableData.map((row)=>{
    sumDO += row['do'];  
    sumINT += row['int'];  
    sumMG += row['mg'];  
    sumNM += row['nm'];  
    sumPT += row['pt'];  
    sumRM += row['rm'];  
    sumRX += row['rx'];  
    sumTC += row['tc'];  
    sumUS += row['us'];
  })
*/

  return (
    <div className="tableBootstrap metrics-group" style={{}}>
      <h3 className="title">Produtividade</h3>
      <Table striped style={{'fontSize':12, 'width':900}}>
        <thead>
          <tr>
            <th>Mês</th>
            <th>DO</th>
            <th>INT</th>
            <th>MG</th>
            <th>NM</th>
            <th>PT</th>
            <th>RM</th>
            <th>RX</th>
            <th>TC</th>
            <th>US</th>
          </tr>
        </thead>
        <tbody>
        {
        newTableData.map((row)=>{
          //soma todas as colunas
          sumDO += row['do'];  
          sumINT += row['int'];  
          sumMG += row['mg'];  
          sumNM += row['nm'];  
          sumPT += row['pt'];  
          sumRM += row['rm'];  
          sumRX += row['rx'];  
          sumTC += row['tc'];  
          sumUS += row['us'];

          //retorna apenas os dados das colunas, fora do .map é retornada a linha de soma das colunas
          return(
          <tr>
            <td>{row.mes.toString().indexOf(".") && row.mes.toString().split('.')[1] != undefined && row.mes.toString().split('.')[1].length>1?row.mes.toString().slice(0,-1):row.mes}</td>
            <td>{row.do.toString().indexOf(".") && row.do.toString().split('.')[1] != undefined && row.do.toString().split('.')[1].length>1?row.do.toString().slice(0,-1).replace(".",","):row.do.toString().replace(".",",")}</td>
            <td>{row.int.toString().indexOf(".") && row.int.toString().split('.')[1] != undefined && row.int.toString().split('.')[1].length>1?row.int.toString().slice(0,-1).replace(".",","):row.int.toString().replace(".",",")}</td>
            <td>{row.mg.toString().indexOf(".") && row.mg.toString().split('.')[1] != undefined && row.mg.toString().split('.')[1].length>1?row.mg.toString().slice(0,-1).replace(".",","):row.mg.toString().replace(".",",")}</td>
            <td>{row.nm.toString().indexOf(".") && row.nm.toString().split('.')[1] != undefined && row.nm.toString().split('.')[1].length>1?row.nm.toString().slice(0,-1).replace(".",","):row.nm.toString().replace(".",",")}</td>
            <td>{row.pt.toString().indexOf(".") && row.pt.toString().split('.')[1] != undefined && row.pt.toString().split('.')[1].length>1?row.pt.toString().slice(0,-1).replace(".",","):row.pt.toString().replace(".",",")}</td>
            <td>{row.rm.toString().indexOf(".") && row.rm.toString().split('.')[1] != undefined && row.rm.toString().split('.')[1].length>1?row.rm.toString().slice(0,-1).replace(".",","):row.rm.toString().replace(".",",")}</td>
            <td>{row.rx.toString().indexOf(".") && row.rx.toString().split('.')[1] != undefined && row.rx.toString().split('.')[1].length>1?row.rx.toString().slice(0,-1).replace(".",","):row.rx.toString().replace(".",",")}</td>
            <td>{row.tc.toString().indexOf(".") && row.tc.toString().split('.')[1] != undefined && row.tc.toString().split('.')[1].length>1?row.tc.toString().slice(0,-1).replace(".",","):row.tc.toString().replace(".",",")}</td>
            <td>{row.us.toString().indexOf(".") && row.us.toString().split('.')[1] != undefined && row.us.toString().split('.')[1].length>1?row.us.toString().slice(0,-1).replace(".",","):row.us.toString().replace(".",",")}</td>
          </tr>
          )
        })}
          <tr>
            <td><b>TOTAL:</b></td>
            <td>{sumDO.toFixed(2).slice(0,-1).toString().replace(".",",")}</td>
            <td>{sumINT.toFixed(2).slice(0,-1).toString().replace(".",",")}</td>
            <td>{sumMG.toFixed(2).slice(0,-1).toString().replace(".",",")}</td>
            <td>{sumNM.toFixed(2).slice(0,-1).toString().replace(".",",")}</td>
            <td>{sumPT.toFixed(2).slice(0,-1).toString().replace(".",",")}</td>
            <td>{sumRM.toFixed(2).slice(0,-1).toString().replace(".",",")}</td>
            <td>{sumRX.toFixed(2).slice(0,-1).toString().replace(".",",")}</td>
            <td>{sumTC.toFixed(2).slice(0,-1).toString().replace(".",",")}</td>
            <td>{sumUS.toFixed(2).slice(0,-1).toString().replace(".",",")}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default TableBootstrap;