import React from 'react';
import PropTypes from 'prop-types';

const Identification = ({ employee }) => {
    return (
        <h1 className="employee identification">
            {employee.name} - {employee.clinical.council}: {employee.clinical.number} {employee.clinical.state}
            {employee.clinical.specialty && <span className="specialty">{employee.clinical.specialty}</span>}
        </h1>
    );
};

Identification.propTypes = {
    employee: PropTypes.shape({
        name: PropTypes.string.isRequired,
        clinical: PropTypes.shape({
            number:   PropTypes.isRequired,
            state:     PropTypes.string.isRequired,
            council:   PropTypes.string.isRequired,
            specialty: PropTypes.string,
        })
    })
};


export default Identification;
