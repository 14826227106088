import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import GraphIcon from 'assets/images/icons/graph.png';

const settings = {
  advanced: {
    subtitle: 'Ambulatoriais - Núcleo de Especialidades',
    keys: {
      first:     'RM23',
      second:    'RM22',
      specialty: 'RM42'
    }
  },

  surgery: {
    subtitle: 'Cirurgia Ambulatorial - Itaim',
    keys: {
      first:     'RM25',
      second:    'RM24',
      specialty: 'RM41'
    }
  }
};

const Generic = ({ scores, settings}) => {
  return (
    <Metric
      title={"Pacientes " + settings.subtitle}
      image={GraphIcon}
      scores={[
        {
          title: 'Ano Anterior',
          value: scores[settings.keys.first]
        },
        {
          title: 'Ano Vigente',
          value: scores[settings.keys.second]
        },
        {
          title: 'Especialidade',
          value: scores[settings.keys.specialty],
          className: 'circle'
        }
      ]}
    />
  );
};

const Ambulatory = ({ scores, type }) => {
  return (
    <div className={`ambulatory metrics-group ambulatory-${type}`}>
      <Generic
        scores={scores}
        settings={settings[type]}
      />
    </div>
  );
};

Ambulatory.propTypes = {
  scores: PropTypes.shape({
    RM10_1: PropTypes.string,
    RM10_2: PropTypes.string,
    RM22: PropTypes.string,
    RM23: PropTypes.string,
    RM24: PropTypes.string,
    RM25: PropTypes.string
  }),
  type: PropTypes.string.isRequired
};

export default Ambulatory;
