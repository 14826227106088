import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/IndMelhorias.png';


const IndexImprovements = ({ scores }) => {

  return (
    <div className="indexImprovements metrics-group" style={{width: 280}}>
      <Metric 
        title="Índice de melhorias"
        image={Icon}
        scores={[
          {
            value: `${scores.rm_47 || "N/A"}`
          },
          {
            value: ``
          },
          {
            value: `${scores.rm_47_porc || "N/A"}%`
          }
        ]}
      />
    </div>
  );
};

export default IndexImprovements;