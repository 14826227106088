import React from 'react';
import PropTypes from 'prop-types';
import Metric from 'components/Scores/Metric';
import GraphIcon from 'assets/images/icons/score6.png';

const Level = ({ score }) => {
    score = {
        value: score,
        title: null
    }

    if(isNaN(score.value)){
        score.title = score.value;
        score.value = '-';
    }
    return (
        
        <div className={`level metrics-group medical-community`}>
            <Metric scores={[score]} title="CM" image={GraphIcon}/>
        </div>
    );
};

Level.propTypes = {
    score: PropTypes.string
};

export default Level;
