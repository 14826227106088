import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'

import { ReactComponent as Doctor } from 'assets/images/icons/user.svg'

const AvatarToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span className="avatar-btn" onClick={onClick} ref={ref}>
        {children}
    </span>
));

const Logout = () => {
    return (
        <div className="avatar">
            <Dropdown>
                <Dropdown.Toggle id="user-menu" as={AvatarToggle}>
                    <Doctor />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/users/sign_out">
                        Sair
                    </Dropdown.Item>
                </Dropdown.Menu>
          </Dropdown>
        </div>

   );
};

export default Logout;