import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/graph.png';


const ProdPerGroup = ({ scores }) => {
    if(scores.prod_final_nao_rx_grupo == "MaisDeUmGrupo"){
        return (
            <div className="prodPerGroup metrics-group" style={{maxWidth: 250}}>
            <Metric 
                title="Produtividade por Grupo"
                image={Icon}
                scores={[
                {
                    title: '',
                    value: `Selecione apenas um grupo` || "N/A"
                }
                ]}
                style={{fontSize:21}}
            />
            </div>
        );
    }else{
        return (
            <div className="prodPerGroup metrics-group" style={{maxWidth: 300}}>
            <Metric 
                title="Produtividade por Grupo"
                image={Icon}
                scores={[
                {
                    title: 'Não RX',
                    value: `${scores.prod_final_nao_rx_grupo || "N/A"}`
                },
                {
                    title: 'RX',
                    value: `${scores.prod_final_rx_grupo || "N/A"}`
                }
                ]}
            />
            </div>
        );
    }
};

export default ProdPerGroup;