import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/IndPeerReview.png';


const IndexPeerReview = ({ scores }) => {

  return (
    <div className="indexPeerReview metrics-group" style={{width: 280}}>
      <Metric 
        title="Índice peer review 2B, 3A e 3B"
        image={Icon}
        scores={[
          {
            value: `${scores.rm_45 || "N/A"}`
          },
          {
            value: `${scores.rm_45_porc || "N/A"}%`
          }
        ]}
      />
    </div>
  );
};

export default IndexPeerReview;