import React from 'react';
import PropTypes from 'prop-types';

import { Row } from 'react-bootstrap';

import {
    Level,
    GeneralScore,
    AdmittedAt,
    AverageGrade
} from 'components/Scores/MedicalCommunity';

const MedicalCommunity = ({ scores, radiologyScore, grade, evaluationType }) => {

    if(evaluationType == 'Anestesista'){
        return (
            <div className="scores scores-assistance">
                <Row className="divider justify-content-md-center">
                    <Level score={scores["cm_level"]==null?'-':scores["cm_level"].split("_")[1]*1} />
                    <GeneralScore score={scores["general_score"]==null?'-':scores["general_score"]} />
                    <AdmittedAt score={scores["rm_05"]} />
                </Row>
            </div>
        );
    }else if(evaluationType == "Radiologista"){
        return (
            <div className="scores scores-assistance">
                <Row className="divider justify-content-md-center">
                    <Level score={radiologyScore.length>0 && radiologyScore[0].cm_level.split("-")[1] || "N/A"} />
                    <GeneralScore score={radiologyScore.length>0 && radiologyScore[0].pontuacao_total || "N/A"} />
                    <AdmittedAt score={scores.RM05} />
                    {grade &&
                    <AverageGrade grade={grade} />
                    }
                </Row>
            </div>
        );
    }else{
        return (
            <div className="scores scores-assistance">
                <Row className="divider justify-content-md-center">
                    <Level score={scores.level} />
                    <GeneralScore score={scores.generalScore} />
                    <AdmittedAt score={scores.RM05} />
                    {grade &&
                    <AverageGrade grade={grade} />
                    }
                </Row>
            </div>
        );
    }
    
};

MedicalCommunity.propTypes = {
    scores: PropTypes.object.isRequired,
    grade: PropTypes.number
};

export default MedicalCommunity;
