import React, { useState } from 'react';

import { Redirect } from 'react-router-dom'

import { Form, Button, Spinner } from 'react-bootstrap';

import UserHelper  from 'helpers/UserHelper';
import AuthService from 'services/AuthService';

const Loading = () => (
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
    />
);

const Auth = (props) => {
    const [validated, setValidated] = useState(false);
    const [loading,   setLoading]   = useState(false);
    const [error,     setError]     = useState(null);

    const headleSignIn = async (event) => {
        setError(null);
        event.preventDefault();

        const form = event.currentTarget;        
        if(!form.checkValidity()){
            return setValidated(true);
        }

        setLoading(true);
        await signIn({
            username: form.username.value, 
            password: form.password.value
        });
        setLoading(false);

    }

    const signIn = async (auth) => {
         try{
            let { data } = await AuthService.signIn(auth);

            if(!data){
                return setError('Usuário ou senha inválido.');
            }
         
            return UserHelper.persist(data);

        }catch(e){
            return setError('Erro inesperado, tente novamente mais tarde.');
        }
    };

    const userAuthenticated = () => {
        if(UserHelper.isAuthenticated()){
            return <Redirect to="/" />
        }
    };

    return (
        <div className="mt-4">
            {userAuthenticated()}

            <Form noValidate validated={validated} onSubmit={headleSignIn}>
                <Form.Group controlId="username">
                    <Form.Label>Usuário</Form.Label>
                    <Form.Control type="text" placeholder="" required />
                </Form.Group>

                <Form.Group controlId="password">
                    <Form.Label>Senha</Form.Label>
                    <Form.Control type="password" placeholder="" required />
                </Form.Group>

                <Button variant="success" type="submit" block disabled={loading}>
                    {!loading ? 'Entrar': <Loading />}
                </Button>

                {error && <p className="text-danger mt-3 text-center">{error}</p>}
            </Form>
        </div>
    );
}

export default Auth;