import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfoCircle from 'assets/images/icons/info-circle.svg';

const Info = ({ image, title, subtitle, hint, tooltip, imgwidth, imgheight }) => {
    return (
        <React.Fragment>
            <img src={image} alt={`Ícone ${title}`} width={imgwidth} height={imgheight}/>
            <h3 className="title">
                {title}
                {tooltip &&
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>{tooltip}</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <img
                                className="tooltip-info-icon"
                                ref={ref}
                                src={InfoCircle}
                                alt={'Info'}
                                {...triggerHandler}
                            />
                        )}
                    </OverlayTrigger>
                }
                <small className="subtitle">{subtitle}</small>
            </h3>
            {hint  && <span className="hint">{hint}</span>}
        </React.Fragment>
    );
};

Info.propTypes = {
    title:    PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    hint:     PropTypes.string,
    tooltip:  PropTypes.string,
    image:    PropTypes.string.isRequired,
    imgwidth:    PropTypes.string,
    imgheight:   PropTypes.string,
};

const HandScoreTooltip = ({ data }) => {
    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">{data.tooltip}</Tooltip>}

        >
            {({ ref, ...triggerHandler }) => (
                <div
                    className={`metric ${!data.className ? '' : data.className}`}
                    {...triggerHandler}
                    ref={ref}
                >
                    <span className="value">{data.value}</span>
                    {data.title &&
                        <span className="title">
                            {data.title}
                            <small>{data.subtitle}</small>
                            <img className="change-my-color" src={InfoCircle} alt={'Info'}/>
                        </span>
                    }
                </div>
            )}
        </OverlayTrigger>
    );
}

const HandScore = ({ data, style }) => {
    return(
        <div className={`metric ${!data.className ? '' : data.className}`}>
            <span className="value" style={style}>{data.value}</span>
            {data.title &&
                <span className="title">
                    {data.title}
                    <small>{data.subtitle}</small>
                </span>
            }
        </div>
    );
}

const Score = ({ data, style }) => {

    if(data.content){
        return data.content;
    }

    return (
        <>
        {
            data.tooltip
            ? <HandScoreTooltip data={data} />
            : <HandScore data={data} style={style} />
        }
        </>
    );
};

const Metric = ({ title, subtitle, hint, scores, image, description, info, imgwidth, imgheight, style}) => {
    info = info || {};
    return (
        <div className={`metric-group ${scores.length > 1 ? '' : ' only-one'}`}>
            <Info title={title}  subtitle={subtitle} image={image} hint={hint} {...info} imgwidth={imgwidth} imgheight={imgheight}/>

            <div className="details">
                {description && <p className="description">{description}</p>}
                <div className="metrics">
                    {scores.map((score, i) =>
                        <Score data={score} key={i} style={style}/>
                    )}

                    <div className="clearfix"></div>
                </div>

            </div>

            <div className="clearfix"></div>
        </div>
    );
};

Metric.propTypes = {
    title:       PropTypes.string.isRequired,
    subtitle:    PropTypes.string,
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    hint:        PropTypes.string,
    image:       PropTypes.string.isRequired,
    scores:      PropTypes.array.isRequired,
    info:        PropTypes.object,
};

export {
   Info,
   Score
}

export default Metric;
