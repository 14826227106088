import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/meeting.png';

const Meeting = ({ scores }) => {
  return (
    <div className="meeting metrics-group">
      <Metric 
        title="Participação nas Reuniões do Corpo Clínico"
        image={Icon}
        scores={[
          {
            value: scores.RM32
          }
        ]}
      />
    </div>
  );
};

Meeting.propTypes = {
  scores: PropTypes.shape({
    RM32: PropTypes.string.isRequired
  })
};

export default Meeting;