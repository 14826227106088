import React from 'react';
import PropTypes from 'prop-types';

import { Row } from 'react-bootstrap';

import {
    Training,
    Meeting,
    Commission,
    Publication,
    OrientationStrictoSensu,
    OrientationLatoSensu,
    Event,
    MedicalResidencyCoordination,
    MedicalResidencyPreceptorship,
    MedicalResidencySupervision
} from 'components/Scores';

const Relationship = ({ scores, year }) => {

    return (
        <div className="scores scores-relationship">
            <h2>
                Indicadores de Relação com o Hospital e de Conhecimento
            </h2>

            <Row className="justify-content-md-center divider">
                <Training scores={scores} />
                <Meeting scores={scores} />
                <Commission scores={scores} />
                <Publication scores={scores} />
                <OrientationStrictoSensu scores={scores} />
                <OrientationLatoSensu scores={scores} />
                <Event scores={scores} />
                <MedicalResidencyCoordination scores={scores} />
                <MedicalResidencyPreceptorship scores={scores} />
                <MedicalResidencySupervision scores={scores} />
            </Row>
        </div>
    );
};

Relationship.propTypes = {
    scores: PropTypes.object.isRequired
};

export default Relationship;