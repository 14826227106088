import React from 'react';

import { Container, Navbar } from 'react-bootstrap';

import Logo from 'assets/images/logo.png';
import LogoHSL from 'assets/images/logo-hsl.png';

import Logout from 'components/Auth/Logout';

const Header = () => {
    return (
        <Navbar bg="white" className="header" fixed="top">
            <Container>
                <img
                    className="logo logo-feedback"
                    src={Logo}
                    alt="Logo Feedback Corpo Clínico"
                />
                
                <div className="logo logo-divider"></div>

                <img
                    className="logo logo-hsl"
                    src={LogoHSL}
                    alt="Logo Hospital Sírio Libanês"
                />

                <Navbar.Collapse className="justify-content-end">
                    <Logout />
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;