import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/IndMelhorias.png';


const ProdPerPeriodAN = ({ scores }) => {

  return (
    <div className="prodPerPeriodAN metrics-group" style={{width: 280}}>
      <Metric 
        title="Produtividade por Período"
        image={Icon}
        scores={[
          {
            value: `${scores.rm_56}`
          }
        ]}
        info={{
          tooltip:'Total de anestesias realizadas no período selecionado.'
        }}
      />
    </div>
  );
};

export default ProdPerPeriodAN;