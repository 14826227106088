import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/orientation.png';

const Orientation = ({ scores }) => {
  return (
    <div className="orientation-stricto-sensu metrics-group">
      <Metric 
        title="Orientações em Pós Graduação Stricto Sensu"
        image={Icon}
        scores={[
          {
            value: scores.RM34
          }
        ]}
      />
    </div>
  );
};

Orientation.propTypes = {
  scores: PropTypes.shape({
    RM34: PropTypes.string.isRequired
  })
};

export default Orientation;