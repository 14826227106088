import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/graph.png';


const ProdPerPeriod = ({ scores }) => {

  return (
    <div className="prodPerPeriod metrics-group" style={{maxWidth: 330}}>
      <Metric 
        title="Produtividade por Período"
        image={Icon}
        scores={[
          {
            title: 'Não RX',
            value: `${scores.rm_prod_per_nao_rx || "N/A"}`
          },
          {
            title: 'RX',
            value: `${scores.rm_prod_per_rx || "N/A"}`
          },
          {
            value: `${scores.rm_prod_grupo_total || "N/A"}%`
          }
        ]}
      />
    </div>
  );
};

export default ProdPerPeriod;