import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/AchadoCritico.png';


const SurgicalProphylaxis = ({ scores }) => {

  return (
    <div className="surgicalProphylaxis metrics-group" style={{width: 280}}>
      <Metric 
        title="Conformidade de Profilaxia Cirúrgica (ATB)"
        image={Icon}
        scores={[
          {
            value: `${scores.rm_59}%`
          }
        ]}
        info={{
          tooltip:'Administração do ATB profilático dentro do tempo preconizado.'
        }}
      />
    </div>
  );
};

export default SurgicalProphylaxis;