import Service from './Service';
import api from 'config/api';

class IndicatorService extends Service{

    /**
     * Obtem os dados dos indicadores
     *
     * @param  { String }
     * @return { Promise{Object} }
     * 
     */
    static all(period){
        let url = `${api.url}/v1/indicators/${period}`;
        return this.get(url);
    }
}

export default IndicatorService;