import React from 'react';
import PropTypes from 'prop-types';

import { Row } from 'react-bootstrap';

import HyperlinkProportion from '../../components/Scores/HyperlinkProportion';
import IndexEdition from '../../components/Scores/IndexEdition';
import IndexPeerReview from '../../components/Scores/IndexPeerReview';
import CriticalFinding from '../../components/Scores/CriticalFinding';
import IndexImprovements from '../../components/Scores/IndexImprovements';
import KeyImageProportion from '../../components/Scores/KeyImageProportion';
import ScientificAcademicParticipation from '../../components/Scores/ScientificAcademicParticipation';

import Icon from 'assets/images/icons/hospital.png';



const AssistanceRX = ({ scores, evaluationType, monthsList, group }) => {

    //filtra os meses selecionados
    //let monthsList = ["Janeiro","Fevereiro","Março"];
    let newMonthScores = [];
    let newScores = [];
    scores.map(score=>{
        if(monthsList.includes(score.mes)){
            newMonthScores.push(score);
        }
    })
    //filtra por grupo
    newMonthScores.map(score=>{
        if(group=='Todos'){
            newScores.push(score);
        }else if(group.includes(score.grupo_0)){
            newScores.push(score);
        }
    })

    let finalScore = {};

        
    let listaTotal = [];
    let listaMedia = {};
    let listaKeys = scores.length > 0?Object.keys(scores[0]).slice(4):[];

    for(let i=0;i<listaKeys.length; i++){
        listaTotal.push(newScores.reduce((list, obj)=>list + parseFloat(obj[listaKeys[i]]),0))
    }
    //console.log("LISTATOTAL EFFICIENCY",listaTotal);

    for(let i=0;i<listaTotal.length; i++){
        if(listaKeys[i]=="rm_achado"){
            listaMedia[listaKeys[i]] = (parseFloat(listaTotal[i]).toFixed(2))
        }else if(listaKeys[i]=="rm_achado_porc"){
            listaMedia[listaKeys[i]] = (parseFloat((listaTotal[i]/newScores.length)).toFixed(2))
        }else{
            listaMedia[listaKeys[i]] = (parseFloat(listaTotal[i]/newScores.length).toFixed(2))
        }
        if(listaMedia[listaKeys[i]].split(".")[1] == "00"){
            listaMedia[listaKeys[i]] = listaMedia[listaKeys[i]].split(".")[0];
        }
    }

    if(monthsList.length == 0){
        for(let i=0;i<listaKeys.length; i++){
            listaMedia[listaKeys[i]] = 0;
        }
    }

    //console.log("LISTA MEDIA", listaMedia)

    finalScore = listaMedia;
        
    Object.keys(finalScore).map((key)=>{
        if(finalScore[key] != NaN && finalScore[key] != null && finalScore[key] != undefined){
            finalScore[key] = finalScore[key].toString().replace(".",",")
        }
    });

    return (
        <div className="scores scores-assistance">
            <h2>
                Indicadores de prática assistencial
            </h2>

            <Row className="divider justify-content-md-center">
                <div className="general-group">
                    <div className="logo-box">
                        <IndexImprovements scores={finalScore} />
                        <IndexEdition scores={finalScore} />
                        <IndexPeerReview scores={finalScore} />
                        <CriticalFinding scores={finalScore} />
                    </div>
                </div>
            </Row>
            <Row className="divider justify-content-md-center">
                <div className="general-group">
                    <div className="logo-box">
                        <HyperlinkProportion scores={finalScore} />
                        <KeyImageProportion scores={finalScore} />
                        <ScientificAcademicParticipation scores={finalScore} />
                    </div>
                </div>
            </Row>
        </div>
    );
    
};

AssistanceRX.propTypes = {
    scores: PropTypes.object.isRequired
};

export default AssistanceRX;
