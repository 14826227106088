import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';

import ProdPerPeriodAN from '../../components/Scores/ProdPerPeriodAN';
import ProdPerAnesthesyPerPeriod from '../../components/Scores/ProdPerAnesthesyPerPeriod';
import CNQ from '../../components/Scores/CNQ';
import HypothermiaPrev from '../../components/Scores/HypothermiaPrev';
import HypothermiaAverage from '../../components/Scores/HypothermiaAverage';
import SurgicalProphylaxis from '../../components/Scores/SurgicalProphylaxis';

const AssistanceAN = ({ scores }) => {

    scores = scores[0];

    return (
        <div className="scores scores-assistance">
            <h2>
                Indicadores de Prática Assistencial
            </h2>

            <Row className="divider justify-content-md-center">
                <ProdPerPeriodAN scores={scores} />
                <ProdPerAnesthesyPerPeriod scores={scores} />
                <CNQ scores={scores} />
            </Row>
            <Row className="divider justify-content-md-center">
                <HypothermiaPrev scores={scores} />
                <HypothermiaAverage scores={scores} />
                <SurgicalProphylaxis scores={scores} />
            </Row>
        </div>
    );
};

AssistanceAN.propTypes = {
    scores: PropTypes.object.isRequired
};

export default AssistanceAN;
