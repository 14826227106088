import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/training.png';

const Training = ({ scores }) => {
  return (
    <div className="training metrics-group">
      <Metric 
        title="Treinamentos Institucionais Obrigatórios"
        image={Icon}
        scores={[
          {
            value: scores.RM31
          }
        ]}
      />
    </div>
  );
};

Training.propTypes = {
  scores: PropTypes.shape({
    RM31: PropTypes.string.isRequired
  })
};

export default Training;