import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/PropHyperlink.png';


const HypothermiaAverage = ({ scores }) => {

  return (
    <div className="hypothermiaAverage metrics-group" style={{width: 280}}>
      <Metric 
        title="Média da conformidade na prevenção de hipotermia"
        image={Icon}
        scores={[
          {
            value: `${scores.rm_60_1}%`
          }
        ]}
      />
    </div>
  );
};

export default HypothermiaAverage;