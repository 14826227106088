import React from 'react';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import MakeOptions from './options';

const Cost = ({ data, title }) => {
    const options = MakeOptions(data, title);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default Cost;