import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/advice.png';

const MedicalResidency = ({ scores }) => {
  return (
    <div className="medical-residency medical-residency-preceptorship metrics-group">
      <Metric 
        title="Preceptoria em Programa de Residência Médica"
        image={Icon}
        scores={[
          {
            value: scores.RM39
          }
        ]}
        imgheight="76"
      />
    </div>
  );
};

MedicalResidency.propTypes = {
  scores: PropTypes.shape({
    RM39: PropTypes.string.isRequired
  })
};

export default MedicalResidency;