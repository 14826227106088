import React from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Icon from 'assets/images/icons/medical-record.png';

const MedicalRecord = ({ scores }) => {
  return (
    <div className="medical-record metrics-group metrics-group">
      <Metric 
        title="Prontuário"
        hint="Não use Siglas nos Resumos de Alta e nos Termos de Consentimento."
        image={Icon}
        scores={[
          {
            title: 'Preenchimento de Resumo de Alta Eletrônico',
            value: scores.RM26
          },
          {
            title: 'Preenchimento de Descrição Cirúrgica Eletrônica',
            value: scores.RM27
          }
        ]}
      />
    </div>
  );
};

MedicalRecord.propTypes = {
  scores: PropTypes.shape({
    RM26: PropTypes.string.isRequired,
    RM27: PropTypes.string.isRequired
  })
};

export default MedicalRecord;