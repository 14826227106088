import Service from './Service';
import api from 'config/api';

class AuthService extends Service{

    /**
     * Faz authenticação do usuário
     *
     * @param  { Object } {username: String, password: String}
     * @return { Promise{Object} }
     * 
     */
    static signIn(data){
        let url = `${api.auth_url}/employee`;
        return this.post(url, data);
    }
}

export default AuthService;