import React from 'react';

import Logo from 'assets/images/logo.png';
import LogoHSL from 'assets/images/logo-hsl.png';
import Auth from 'components/Auth';

const SignIn = () => {
    return (
        <div className="auth">
            <div className="brand">
                <img
                    className="logo logo-feedback"
                    src={Logo}
                    alt="Logo Feedback Corpo Clínico"
                />

                <img
                    className="logo logo-hsl"
                    src={LogoHSL}
                    alt="Logo Hospital Sírio Libanês"
                />
            </div>

            <Auth />
        </div>
    )
}

export default SignIn;