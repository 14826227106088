import decode from 'jwt-decode';

/**
 * Helper para lidar com os dados de usuário
 *
 * @public
 */
class UserHelper{

    /**
     * Obtem os dados de usuário.
     *
     * @return { Object } userdata
     *
     */
    static get(){
        if(this.userdata){
           return this.userdata; 
        }

        let userdata = localStorage.getItem('userdata');

        if(!userdata){
            return null;
        }

        this.userdata = JSON.parse(userdata);
        return this.userdata;
    }

    /**
     * Verifica se o usuário está autenticado.
     *
     * @return { Boolean }
     *
     */
    static isAuthenticated(){
        let userdata = this.get();

        if(!userdata){
            return false;
        }

        let token = decode(userdata.accessToken);
        let currentTime = Date.now() / 1000;

        return token.exp > currentTime;
    }

    /**
     * Obtem a chave de autorização do usuário
     * 
     * @return { String }
     * 
     */
    static getAuthorization(){
        let userdata = this.get();
        return !userdata ? '' : userdata.accessToken;
    }

    /**
     * Seta os dados do usuário em LocalStorage
     *
     * @param { Object } userdata
     * @return { void }
     *
     */
    static persist(userdata){
        this.purge();

        if(!userdata){
            return;
        }

        localStorage.setItem('userdata', JSON.stringify(userdata));
    }

    /**
     * Limpa os dados de usuário do Local Storage.
     *
     * @return { void }
     *
     */
    static purge(){
        localStorage.removeItem('userdata');
        this.userdata = null;
    }
}

export default UserHelper;